import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CategoryIcon from '@material-ui/icons/Category';
import BusinessIcon from '@material-ui/icons/Business';
import EuroIcon from '@material-ui/icons/Euro';
import RoomIcon from '@material-ui/icons/Room';
import ViewListIcon from '@material-ui/icons/ViewList';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Permissions } from '../permissions/permissions';
import WarehouseAdminSubMenu from './WarehouseAdminSubMenu';
import StoreIcon from '@material-ui/icons/Store';
import KitchenIcon from '@material-ui/icons/Kitchen';
import AppleIcon from '@material-ui/icons/Apple';
import CropFreeIcon from '@material-ui/icons/CropFree';
import DehazeIcon from '@material-ui/icons/Dehaze';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ListIcon from '@material-ui/icons/List';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import StyleIcon from '@material-ui/icons/Style';
import LooksIcon from '@material-ui/icons/Looks';
import TransformIcon from '@material-ui/icons/Transform';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import CoffeeIcon from '@material-ui/icons/LocalCafe';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import StorefrontIcon from '@material-ui/icons/Storefront';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import StarIcon from '@material-ui/icons/Star';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DvrIcon from '@material-ui/icons/Dvr';

const menuList = [
    {
        name: 'Utils',
        children: [
            {
                link: `import-data-from-foorban`,
                primaryText: 'Spreadsheet',
                resource: 'utils',
                action: 'importDataFromFoorban',
                leftIcon: <DvrIcon />
            },
        ]
    },
    {
        name: 'Corriere',
        children: [
            {
                primaryText: 'Missioni',
                resource: Permissions.deliveryManMission.feature,
                leftIcon: <LocalShippingIcon />
            },
            {
                link: `${Permissions.deliveryManMissionJob.feature}/tasks`,
                primaryText: 'Task in Hub',
                resource: Permissions.deliveryManMissionJob.feature,
                leftIcon: <AssignmentIcon />
            },
        ]
    },
    {
        name: 'Items',
        children: [
            {
                primaryText: 'Categorie Interne',
                resource: Permissions.internalCategory.feature,
                leftIcon: <CategoryIcon />
            },
            {
                primaryText: 'Items',
                resource: Permissions.item.feature,
                leftIcon: <BusinessIcon />
            },
            {
                primaryText: 'Tasse',
                resource: Permissions.tax.feature,
                leftIcon: <EuroIcon />
            },
            {
                primaryText: 'Categorie Ecommerce',
                resource: Permissions.clientCategory.feature,
                leftIcon: <CategoryIcon />
            },
            {
                primaryText: 'Categoria di previsione',
                resource: Permissions.forecastCategory.feature,
                leftIcon: <CategoryIcon />
            }
        ]
    },
    {
        name: 'Configurazioni',
        children: [
            {
                primaryText: 'Luoghi',
                resource: Permissions.place.feature,
                leftIcon: <RoomIcon />
            },
            {
                primaryText: 'Utenti backoffice',
                resource: Permissions.user.feature,
                leftIcon: <PeopleIcon />
            },
            {
                primaryText: 'Ruoli backoffice',
                resource: Permissions.role.feature,
                leftIcon: <VerifiedUserIcon />
            },
            {
                primaryText: 'Lock',
                resource: Permissions.lock.feature,
                leftIcon: <KitchenIcon />
            },
            {
                primaryText: 'Locker',
                resource: Permissions.locker.feature,
                leftIcon: <DehazeIcon />
            },
            {
                primaryText: 'Business Unit',
                resource: Permissions.businessUnit.feature,
                leftIcon: <BusinessIcon />
            }
        ]
    },
    {
        name: 'Wallet',
        children: [
            {
                primaryText: 'AccAccount',
                resource: Permissions.walletAccAccount.feature,
                leftIcon: <StyleIcon />
            },
            {
                primaryText: 'Type',
                resource: Permissions.walletAccAccountType.feature,
                leftIcon: <AccountBalanceWalletIcon />
            },
            {
                primaryText: 'Transfer',
                resource: Permissions.walletTransfer.feature,
                action: Permissions.walletTransfer.actions.heavyReadAll,
                leftIcon: <TransformIcon />
            },
            {
                primaryText: 'Manual Transfer',
                resource: Permissions.walletManualTransfer.feature,
                leftIcon: <TransformIcon />
            },
        ]
    },
    {
        name: 'Buono Aziendale',
        children: [
            {
                primaryText: 'Crediti',
                resource: Permissions.corporateCredit.feature,
                leftIcon: <MoneyOffIcon />
            },
            {
                primaryText: 'Crediti usati',
                resource: Permissions.corporateCreditHistory.feature,
                leftIcon: <ReceiptIcon />
            },
        ],
    },
    {
        name: 'Vendita',
        children: [
            {
                primaryText: 'Punti di Vendita',
                resource: Permissions.sellingPoint.feature,
                leftIcon: <ViewListIcon />
            },
            {
                primaryText: 'Listino di Vendita',
                resource: Permissions.priceList.feature,
                leftIcon: <ShoppingCartIcon />
            },
            {
                primaryText: 'Ordini di Vendita',
                resource: Permissions.sellingOrder.feature,
                leftIcon: <ReceiptIcon />
            },
            {
                primaryText: 'Layout',
                resource: Permissions.layout.feature,
                leftIcon: <PhotoLibraryIcon />
            },
        ]
    },
    {
        name: 'Pagamenti',
        icon: <CreditCardIcon />,
        children: [
            {
                primaryText: 'Payment',
                resource: Permissions.payment.feature,
                leftIcon: <MonetizationOnIcon />
            },
            {
                primaryText: 'Attempt',
                resource: Permissions.paymentAttempt.feature,
                leftIcon: <CreditCardIcon />
            },
            {
                primaryText: 'Gateway',
                resource: Permissions.gateway.feature,
                leftIcon: <LooksIcon />
            },
            {
                primaryText: 'Config Recharge',
                resource: Permissions.walletRechargeConfig.feature,
                leftIcon: <SettingsApplicationsIcon />
            },
        ]
    },
    {
        name: 'Acquisto',
        icon: <LocalOfferIcon />,
        children: [
            {
                primaryText: 'Lista Fornitori',
                resource: Permissions.supplier.feature,
                leftIcon: <ViewListIcon />
            },
            {
                primaryText: 'Listino Fornitori',
                resource: Permissions.supplierPriceList.feature,
                leftIcon: <LocalOfferIcon />
            },
            {
                primaryText: 'Punti di Acquisto',
                resource: Permissions.purchasePoint.feature,
                leftIcon: <StoreIcon />
            },
            {
                primaryText: 'Ordini di Acquisto',
                resource: Permissions.purchaseOrder.feature,
                leftIcon: <ReceiptIcon />
            },
        ]
    },
    {
        name: 'Marketing',
        icon: <AppleIcon />,
        children: [
            {
                primaryText: 'QR codes and quick links',
                resource: Permissions.code.feature,
                leftIcon: <CropFreeIcon />
            },
            {
                primaryText: 'File',
                resource: Permissions.files.feature,
                leftIcon: <AttachmentIcon />
            },
            {
                primaryText: 'Promozioni',
                resource: Permissions.promotion.feature,
                leftIcon: <MoneyOffIcon />
            }
        ]
    },
    {
        name: 'Gestione utenti finali',
        children: [
            {
                primaryText: 'Utenti',
                resource: Permissions.B2CUser.feature,
                leftIcon: <AccountBoxIcon />
            },
            {
                primaryText: 'Lista domini',
                resource: Permissions.domains.feature,
                leftIcon: <ListIcon />
            }
        ]
    },
    {
        name: 'Gestione clienti B2B',
        children: [
            {
                primaryText: 'CorporateCustomers',
                resource: Permissions.corporateCustomers.feature,
                leftIcon: <ApartmentIcon />
            },
            {
                primaryText: 'Cluster',
                resource: Permissions.clientCluster.feature,
                leftIcon: <AccountBoxIcon />
            },
            {
                primaryText: 'Clienti',
                resource: Permissions.B2BClient.feature,
                leftIcon: <AccountBoxIcon />
            },
        ]
    },
    {
        name: 'Magazzino',
        children: [
            {
                primaryText: 'Lotti',
                resource: Permissions.lot.feature,
                leftIcon: <ViewColumnIcon />
            },
            {
                primaryText: 'Inventario',
                resource: Permissions.warehouseInventory.feature,
                leftIcon: <ViewColumnIcon />
            },
            {
                primaryText: 'Location',
                resource: Permissions.warehouseLocation.feature,
                leftIcon: <LocationCityIcon />
            },
            {
                primaryText: 'Transfer',
                resource: Permissions.transfer.feature,
                leftIcon: <CompareArrowsIcon />
            },
            {
                primaryText: 'Transfer Picking',
                resource: Permissions.transferPicking.feature,
                leftIcon: <CompareArrowsIcon />
            },
            {
                link: 'bulk-action-physical-item-transfer',
                primaryText: 'Physical Transfer',
                resource: Permissions.physicalItemTransfer.feature,
                action: Permissions.physicalItemTransfer.actions.heavyReadAll,
                leftIcon: <TrendingFlatIcon />
            },
            {
                primaryText: 'Hub',
                resource: Permissions.hub.feature,
                leftIcon: <DeviceHubIcon />
            },
            {
                primaryText: 'Mission',
                resource: Permissions.mission.feature,
                leftIcon: <TransferWithinAStationIcon />
            },
             {
                primaryText: 'Mission V2',
                resource: Permissions.missionV2.feature,
                leftIcon: <TransferWithinAStationIcon />
            },
            {
                primaryText: 'Servizi Missione',
                resource: Permissions.missionJob.feature,
                leftIcon: <AssignmentIcon />
            },
            {
                primaryText: 'P. Carichi/Scarichi',
                resource: Permissions.missionJobTransferAssignment.feature,
                leftIcon: <CompareArrowsIcon />
            },
            {
                primaryText: 'Configurazione Task',
                resource: Permissions.taskConfig.feature,
                leftIcon: <SettingsApplicationsIcon />
            },
            {
                primaryText: 'Corrieri',
                resource: Permissions.deliveryMan.feature,
                leftIcon: <LocalShippingIcon />
            },
            {
                primaryText: 'Skills Corrieri',
                resource: Permissions.deliveryManSkill.feature,
                leftIcon: <StarIcon />
            },
        ]
    },
    {
        name: 'Coffee',
        children: [
            {
                primaryText: 'Tipo',
                resource: Permissions.coffeeType.feature,
                leftIcon: <CoffeeIcon />
            },
            {
                primaryText: 'Tipo Solubile',
                resource: Permissions.solubleType.feature,
                leftIcon: <EmojiFoodBeverageIcon />
            },
            {
                primaryText: 'Ricette',
                resource: Permissions.coffeeRecipe.feature,
                leftIcon: <ReceiptIcon />
            },
            {
                primaryText: 'Lista ricette',
                resource: Permissions.coffeeRecipeList.feature,
                leftIcon: <ListIcon />
            },
            {
                primaryText: 'Punti di Vendita',
                resource: Permissions.coffeeSellingPoint.feature,
                leftIcon: <StorefrontIcon />
            },
            {
                primaryText: 'Macchine',
                resource: Permissions.coffeeMachine.feature,
                leftIcon: <RemoveFromQueueIcon />
            },
        ]
    }
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    box: {
        maxHeight: '85vh',
        overflow: 'scroll',
        width: drawerWidth
    }
}));

const Menu = ({ onMenuClick, logout }) => {
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const classes = useStyles();
    const { permissions } = usePermissions();

    if(!open)
      return null;

    return (
        <Box className={classes.box} position="fixed">
         
         {permissions && menuList.map((item, index) => {
                let children = item.children.map(({primaryText, resource, action, leftIcon, link}) => {

                  if(action){
                    if(!permissions.includes(`${resource}-${action}`)){
                      return null
                    }
                  } else if(!permissions.includes(`${resource}-${Permissions[resource].actions.ReadAll}`)) {
                    return null
                  }
                  
                  return <MenuItemLink
                            key={resource}
                            to={`/${link ? link : resource}`}
                            primaryText={primaryText}
                            leftIcon={leftIcon}
                            sidebarIsOpen={open}
                          />  
                }).filter(x => !!x);

                if(children.length === 0)
                    return null;

                return (
                    <WarehouseAdminSubMenu
                        key={item.name}
                        name={item.name}
                        isOpen={open}>
                        {children}
                    </WarehouseAdminSubMenu>
                );
            })}
        </Box>
    );
}

export default withRouter(Menu);